import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import './whatwedo.css';
import ServicesImage from '../../assets/images/whatwedo.avif';
import whatwedoImg from "../../assets/images/our-services.jpeg"

const WhatWeDo = () => {

    const [zoomLevel, setZoomLevel] = useState(1);
    const [activeItems, setActiveItems] = useState([]);
    const location = useLocation();
    const [openIndex, setOpenIndex] = useState(null);


    const handleServiceClick = (item) => {
        setActiveItems(prevItems =>
            prevItems.includes(item)
                ? prevItems.filter(i => i !== item)
                : [...prevItems, item]
        );
        setOpenIndex(openIndex === item ? null : item);
    };


    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const topic = params.get('topic');
        if (topic) {
            setActiveItems(prevItems => {
                const updatedItems = [...prevItems];
                if (!updatedItems.includes(topic)) {
                    updatedItems.push(topic);
                }
                return updatedItems;
            });

            const element = document.getElementById(topic);
            if (element) {
                element.scrollIntoView({ behavior: 'smooth' });
            }
        }
    }, [location.search]);


    return (


        <div className="what-we-do-page">
            <div className="exclusive-services">
                <img
                    src={ServicesImage}
                    alt="Exclusive Services"
                    className="background-image2"
                />
            </div>
            <div className="what-we-do-section">
                <h2>Our Exclusive Services</h2>
                <img
                    src={whatwedoImg}
                    alt="What We Do"
                    className="zoom-image"
                    style={{ transform: `scale(${zoomLevel})` }}
                />
            </div>
            <div className="services-content" id="services-content">
                <div className="sidebar">
                    <ul>
                        <div id="Commercial-Aircraft-Support" className="service-section"></div>

                        <li
                            className={`sidebar-item ${activeItems.includes('Commercial-Aircraft-Support') ? 'active' : ''}`}
                            onClick={() => handleServiceClick('Commercial-Aircraft-Support')}
                        >

                            <h3 className="sidebar-title">Commercial Aircraft Support</h3>
                            <span className='sidebar-arrow'>
                                {openIndex === "Commercial-Aircraft-Support" ? '▲' : '▼'}
                            </span>
                            <div className={`sidebar-details${activeItems.includes('Commercial-Aircraft-Support') ? 'active' : ''}`}>
                                <div>
                                    <div className='sidebar-content' >
                                        <p >
                                            ARJUNA AEROSPACE provides comprehensive support for major civil aircraft manufacturers, including:
                                        </p>

                                        <ul >
                                            <li>Airbus</li>
                                            <li>Boeing</li>
                                            <li>Bombardier</li>
                                            <li>Embraer</li>
                                            <li>Fokker</li>
                                        </ul>

                                        <p >
                                            In addition, we offer specialized support for Auxiliary Power Units (APUs) from:
                                        </p>

                                        <p >
                                            Honeywell<br />
                                            Garrett
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <div id="General-Aircraft-Support" className="service-section"></div>

                        <li
                            className={`sidebar-item ${activeItems.includes('General-Aircraft-Support') ? 'active' : ''}`}
                            onClick={() => handleServiceClick('General-Aircraft-Support')}
                        >
                            <h3 className="sidebar-title">General Aircraft Support</h3>
                            <span className='sidebar-arrow'>
                                {openIndex === "General-Aircraft-Support" ? '▲' : '▼'}
                            </span>
                            <div className={`sidebar-details ${activeItems.includes('General-Aircraft-Support') ? 'active' : ''}`}>
                                <div>
                                    <div className='sidebar-content'>

                                        <p >
                                            We provide a comprehensive inventory for both fixed-wing and rotor-wing aircraft, ensuring a broad range of support for various aviation needs.
                                        </p>

                                        <p className='sidebar-subheading' >
                                            Fixed-Wing Aircraft
                                        </p>

                                        <div className='sidebar-content-list' >
                                            <ul >
                                                <li>Aermacchi MB-339</li>
                                                <li>Air Tractor</li>
                                                <li>Airbus</li>
                                                <li>Boeing</li>
                                                <li>Bombardier</li>
                                            </ul>
                                            <ul style={{
                                                flexBasis: '22%',
                                                padding: 0,
                                                marginLeft: '15px',
                                                marginBottom: '15px',
                                                listStyleType: 'disc'
                                            }}>
                                                <li>Beechcraft</li>
                                                <li>CASA</li>
                                                <li>Cessna</li>
                                                <li>Challenger</li>
                                                <li>Chengdu</li>
                                            </ul>
                                            <ul >
                                                <li>Embraer</li>
                                                <li>Falcon</li>
                                                <li>Gulfstream</li>
                                                <li>Harbin</li>
                                                <li>Hawker</li>
                                            </ul>
                                            <ul >
                                                <li>Hongdu</li>
                                                <li>Ilyushin</li>
                                                <li>Lockheed Martin</li>
                                                <li>Mirage</li>
                                                <li>Twin Otter</li>
                                            </ul>
                                        </div>

                                        <p className='sidebar-subheading'>
                                            Rotor-Wing Aircraft
                                        </p>

                                        <div className='sidebar-content-list'>
                                            <ul >
                                                <li>Agusta</li>
                                                <li>Bell</li>
                                                <li>Black Hawk</li>
                                                <li>CH-47</li>
                                                <li>Eurocopter</li>

                                            </ul>
                                            <ul >

                                                <li>Fennec</li>
                                                <li>Mi-8/Mi-17</li>
                                                <li>Panther</li>
                                                <li>Sikorsky</li>

                                            </ul>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </li>
                        <div id="Repair-and-Maintenance-Services" className="service-section"></div>

                        <li
                            className={`sidebar-item ${activeItems.includes('Repair-and-Maintenance-Services') ? 'active' : ''}`}
                            onClick={() => handleServiceClick('Repair-and-Maintenance-Services')}
                        >
                            <h3 className="sidebar-title">Repair and Maintenance Services</h3>
                            <span className='sidebar-arrow'>
                                {openIndex === "Repair-and-Maintenance-Services" ? '▲' : '▼'}
                            </span>
                            <div className={`sidebar-details ${activeItems.includes('Repair-and-Maintenance-Services') ? 'active' : ''}`}>
                                <div>
                                    <div className='sidebar-content'>
                                        <p >
                                            ARJUNA AEROSPACE boasts a robust network of OEM-approved FAA/EASA repair centers and MROs, ensuring high-quality repairs and quick turnaround times for customer aircraft parts.
                                        </p>

                                        <p >
                                            We facilitate the repair and return of aircraft components efficiently and offer comprehensive propeller overhauls through our dedicated service center. Additionally, we specialize in the repair of avionics, nose landing gear (NLG), engine components, hydraulic systems, and more.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <div id="Aviation-Logistics-Solutions" className="service-section"></div>

                        <li
                            className={`sidebar-item ${activeItems.includes('Aviation-Logistics-Solutions') ? 'active' : ''}`}
                            onClick={() => handleServiceClick('Aviation-Logistics-Solutions')}
                        >
                            <h3 className="sidebar-title">Aviation Logistics Solutions</h3>
                            <span className='sidebar-arrow'>
                                {openIndex === "Aviation-Logistics-Solutions" ? '▲' : '▼'}
                            </span>
                            <div className={`sidebar-details  ${activeItems.includes('Aviation-Logistics-Solutions') ? 'active' : ''}`}>
                                <div>
                                    <div className='sidebar-content'>
                                        <p >
                                            We offer customized logistics solutions designed to meet your specific aviation needs.
                                        </p>

                                        <p >
                                            <strong style={{ marginRight: '23px' }}>Timely Delivery:</strong>  We ensure on-time delivery to keep your operations running smoothly.<br />
                                            <strong style={{ marginRight: '37px' }}>AOG Support:</strong> We effectively address AOG (Aircraft on Ground) situations with comprehensive logistics support.<br />
                                            <strong style={{ marginRight: '8px' }}>Quality Standards:</strong> Our shipping, handling, and packing processes adhere to ISO 9001:2015 standards.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <div id="Terms-Conditions" className="service-section"></div>
                        <li
                            className={`sidebar-item ${activeItems.includes('Terms-Conditions') ? 'active' : ''}`}
                            onClick={() => handleServiceClick('Terms-Conditions')}
                        >
                            <h3 className="sidebar-title">Terms & Conditions</h3>
                            <span className='sidebar-arrow'>
                                {openIndex === "Terms-Conditions" ? '▲' : '▼'}
                            </span>
                            <div className={`sidebar-details ${activeItems.includes('Terms-Conditions') ? 'active' : ''}`}>
                                <div>
                                    <div className='sidebar-content'>
                                        <p className='sidebar-subheading'>
                                            Terms & Condition of Sale
                                        </p>

                                        <p >
                                            The communication "the Company" shall mean ARJUNA AEROSPACE, "Customer" shall mean any person, firm, company or corporation whatsoever trading with the Company.
                                        </p>

                                        <p>
                                            The Firm order placed by the Customer should be minimum Order Value: USD 250.00 or AED 1000.00. Orders less than USD 250.00 or AED 1000.00 will not be accepted.
                                        </p>

                                        <p >
                                            The Spare Parts / Goods will be sold to the Customer at the price. Price is subject to change without prior notice on account of any change in duty, tax, surcharge, or levy of any kind. For all wire transfer transactions, a charge of $50.00 per transaction will be levied.
                                        </p>

                                        <p >
                                            Unless otherwise stated, the Company will levy an A.O.G. service charge of $175.00 per shipment for goods dispatched after normal office hours (09:30 - 18:30 Sunday - Thursday).
                                        </p>

                                        <p >
                                            We commit our time of delivery as accurately as possible, but it is not the subject of any condition, warranty, or representation. It is, therefore, subject to extension, but not limited to, delays due to strikes, lockouts, works breakdown, delay in transit, shortage of materials, government regulations or requirements, or any other cause beyond the company's control.
                                        </p>

                                        <p >
                                            Made-to-order or special orders placed by the Customer can’t be canceled. For regular orders, if the Customer cancels the order, then the Customer will be liable to reimburse the Company for all expenses incurred in placing and canceling the order plus a 25% cancellation fee.
                                        </p>

                                        <p >
                                            The Customer shall be responsible for complying with any legislation or regulations governing the importation of the goods in the country of destination and for the payment of any such duties, and the company will not be responsible or entertain any such claim by the customer.
                                        </p>

                                        <p >
                                            At ARJUNA AEROSPACE, we are readily accessible—just an email or call away—to address all your aviation needs.
                                        </p>

                                        <p className='sidebar-subheading'>
                                            Return Policy & Credit
                                        </p>

                                        <p >
                                            We at ARJUNA AEROSPACE are proud of our quality products. We strive to make every effort to provide our customers with the most reliable product. However, if a problem develops in a supplied component that requires return, please contact our Sales Executive. Returned material must be accompanied by a copy of the issued Original FAA8130-3 / EASA-FORM 1 or related paperwork with all listed requirements completed.
                                        </p>

                                        <p >
                                            Returned goods are subject to a minimum restocking fee of 25% of the purchase price. Opened or unsealed items must be recertified and returned to the original condition in which they were shipped. Non-warranty recertification charges are the responsibility of the customer.
                                        </p>

                                        <p >
                                            Reports of shortages must be made within 7 days of receipt of goods. Where delivery is other than in the Company's own transport or carrier, the Company shall not, in any circumstances, be responsible for the loss, damage, or destruction of the goods. The Customer should, in their own interest, give notice to and subsequently claim from the carrier as required by the carrier's regulations. A copy of the notice and claim shall at the same time be sent to the Company, who (whilst the Company cannot accept any responsibility) will give the Customer any assistance the Company considers appropriate in connection with the proper claim against the carrier.
                                        </p>

                                        <p >
                                            If the Customer wishes to return goods purchased from the Company, then a Return Goods Authorization Form must be obtained from the Company. Once completed in full, the RMA should be attached to the original airworthiness documentation (FAA Form 8130 and/or EASA Form One) and returned with the goods. Goods arriving at the Company without a completed RMA form will not be considered for credit and 25% of Re-Stocking will be levied on the customer. If any technical defect or Company has supplied the wrong part against demand or supplied without FAA8130-3 / EASA FORM ONE, then the Company is fully liable to give a Full credit to the Customer and the Customer can return the Unit to the Company for Full Credit.
                                        </p>

                                        <p >
                                            Requests to return goods for credit must be made within 15 days from the date of dispatch from the Company and be accompanied by the Company’s RMA form. And to be returned at the Customer’s entire expense including prepaid freight and customs charges.
                                        </p>

                                        <p >
                                            The Company reserves the right to charge a restocking fee for items correctly supplied but subsequently returned for credit. Restocking / handling charges are 25% of the invoice value on items supplied. Recertification charges may also be applicable if the item has been removed from its original sealed bag and/or if the original airworthiness documentation is not returned. Any special made-to-order items for the Customer and dispatched by the Company are non-refundable and no credit will be issued for such items.
                                        </p>

                                        <p className='sidebar-subheading'>
                                            Payment Terms & Condition
                                        </p>

                                        <p >
                                            Payments are payable in the name of ARJUNA AEROSPACE. The Customer undertakes to pay for all goods or services supplied by the Company within 30 days from the date of invoice. Failure to settle outstanding payments within the company’s specified period can result in the Company withdrawing the Customer’s credit account facilities and taking legal action to recover the overdue amounts and any interest on the outstanding balance.
                                        </p>

                                        <p >
                                            All supplied Spare Parts / Goods remain the property of the Company until the Customer has paid for them in full.
                                        </p>

                                        <p >
                                            Warranty Terms & Conditions
                                        </p>

                                        <p >
                                            The Company warrants any new item to be free from defects in material or workmanship for a period of 12 months from the date of dispatch from OEM or Supplier. For overhaul condition items, the warranty is 06 months from the date of dispatch from the factory or supplier, and for any serviceable or repair item, the warranty is 03 months from the date of dispatch from the factory or supplier. The warranty covers any serviceable rotable supplied by the Company, irrespective of its tagged condition, providing that the Company is notified within 7 days of discovery of any defect and that the defective item is returned together with a fully completed Returned Material Authorization form.
                                        </p>

                                        <p >
                                            The warranty period for any repaired or replaced part is limited to the unexpired period of the original warranty provided and not from the date of the replacement.
                                        </p>

                                        <p >
                                            Where goods supplied by the Company are alleged by the Customer to be defective or unsatisfactory, they shall not form the subject of any claim for work done by or on behalf of the Customer, or for any loss, damage, or expense whatsoever arising directly or indirectly from such defect or unsatisfactory work. Such goods, if returned to the Company and accepted by the Company as defective, having taken into account the preceding clauses, shall either be repaired or replaced by the Company or credit for the price or part thereof will be given to the Customer.
                                        </p>

                                        <p >
                                            Warranty replacement units are charged at the original supply price and should the claim be accepted, then credit will be issued against the invoice for the replacement part.
                                        </p>

                                        <p className='sidebar-subheading'>
                                            The Company will not be liable for all obligations under this Warranty if:
                                        </p>

                                        <p >
                                            The unproven defect in the part is due to misuse or negligence on the part of someone other than the Company or the repair facility that the claim is on. The Company identification mark, name, or serial number has been removed from the part in question. If the part is damaged as the result of another component in the aircraft being faulty. If the testing procedure is outside the parameters laid down by the component's OEM.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>


                {/* <div className="service-details" id='service-details'>
                    <h3>{selectedService.replace(/-/g, ' ')}</h3>
                    <p>{serviceDetails[selectedService]}</p>
                </div> */}
            </div>
            <div></div>
        </div >
    );
};

export default WhatWeDo;
