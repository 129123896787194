import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import Logo from "./logo";
import "./navbar.css";
import AdminIcon from "../assets/images/admin-icon.svg";
import API from "../baseURL.config.json";
import axios from "axios";
import { message } from "antd";
const Navbar = () => {
  const [openDropdown, setOpenDropdown] = useState(null);
  const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [mobile_no1, setmobile_no1] = useState("");
  const [mobile_no2, setmobile_no2] = useState("");
  const [mail, setMail] = useState("");
  const navbarRef = useRef(null);

  const handleDropdownToggle = (dropdown) => {
    setOpenDropdown(openDropdown === dropdown ? null : dropdown);
  };

  const handleLinkClick = () => {
    setOpenDropdown(null);
    setMobileMenuOpen(false); // Close the mobile menu on link click
  };

  const toggleMobileMenu = () => {
    setMobileMenuOpen(!isMobileMenuOpen);
  };

  useEffect(() => {
    //email
    axios
      .get(`${API.devURL}/getGeneralEmail`)
      .then((res) => {
        setMail(res.data.general_email);
      })
      .catch((err) => {
        message.error(err);
      });
    //mobile
    axios
      .get(`${API.devURL}/getMobileNo1`)
      .then((res) => {
        setmobile_no1(res.data.mobile_no_1);
      })
      .catch((err) => {
        message.error(err);
      });
    //mobile2
    axios
      .get(`${API.devURL}/getMobileNo2`)
      .then((res) => {
        setmobile_no2(res.data.mobile_no_2);
      })
      .catch((err) => {
        message.error(err);
      });
    ///////////////////////////
    const handleClickOutside = (event) => {
      if (navbarRef.current && !navbarRef.current.contains(event.target)) {
        setOpenDropdown(null);
        setMobileMenuOpen(false); // Close the menu if clicked outside
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <>
      <div className="top-navbar">
        <div className="container">
          <div className="email-phone">
            {/* <p
              style={{
                color: "#fff",
              }}
            >
              <span role="img" aria-label="phone">
                📞
              </span>{" "}
              {mobile_no1}
            </p>
            <p
              style={{
                color: "#fff",
              }}
            >
              <span role="img" aria-label="phone">
                📞
              </span>{" "}
              {mobile_no2}
            </p>
            <span
              style={{
                color: "#fff",
              }}
            >
              {mail}
            </span> */}
<br></br>
            <b><span role="img" aria-label="phone">📞   </span>  {mobile_no1}</b> <br></br>
            <b><span role="img" aria-label="phone">📞  </span>  {mobile_no2}</b><br></br>

            <b>
              <span role="img" aria-label="email">📧   </span>  {mail}</b><br></br>


          </div>
        </div>
      </div>

      <nav className="navbar" ref={navbarRef}>
        <Link to="/" onClick={handleLinkClick}>
          <Logo />
        </Link>

        {/* Hamburger icon */}
        <div className="hamburger" onClick={toggleMobileMenu}>
          <div className={`bar ${isMobileMenuOpen ? "open" : ""}`}></div>
          <div className={`bar ${isMobileMenuOpen ? "open" : ""}`}></div>
          <div className={`bar ${isMobileMenuOpen ? "open" : ""}`}></div>
        </div>

        <ul className={`nav-links ${isMobileMenuOpen ? "show" : ""}`}>
          <li
            className={`dropdown ${openDropdown === "aboutUs" ? "active" : ""}`}
          >
            <button
              type="button"
              onClick={() => handleDropdownToggle("aboutUs")}
              className="dropdown-link"
            >
              About Us
            </button>
            <ul
              className={`dropdown-menu ${openDropdown === "aboutUs" ? "show" : ""
                }`}
            >
              <li>
                <Link to="/about-us#our-company" onClick={handleLinkClick}>
                  Our Company
                </Link>
              </li>
              <li>
                <Link to="/about-us#vision-mission" onClick={handleLinkClick}>
                  Mission & Vision
                </Link>
              </li>
              <li>
                <Link to="/about-us#management" onClick={handleLinkClick}>
                  Management
                </Link>
              </li>
              <li>
                <Link to="/about-us#gallery" onClick={handleLinkClick}>
                  Gallery
                </Link>
              </li>
            </ul>
          </li>
          <li
            className={`dropdown ${openDropdown === "whatWeDo" ? "active" : ""
              }`}
          >
            <button
              type="button"
              onClick={() => handleDropdownToggle("whatWeDo")}
              className="dropdown-link"
            >
              What We Do
            </button>
            <ul
              className={`dropdown-menu ${openDropdown === "whatWeDo" ? "show" : ""
                }`}
            >
              <li>
                <Link
                  to="/what-we-do?topic=Commercial-Aircraft-Support"
                  onClick={handleLinkClick}
                >
                  Commercial Aircraft Support
                </Link>
              </li>
              <li>
                <Link
                  to="/what-we-do?topic=General-Aircraft-Support"
                  onClick={handleLinkClick}
                >
                  General Aircraft Support
                </Link>
              </li>
              <li>
                <Link
                  to="/what-we-do?topic=Repair-and-Maintenance-Services"
                  onClick={handleLinkClick}
                >
                  Repair and Maintenance Services
                </Link>
              </li>
              <li>
                <Link
                  to="/what-we-do?topic=Aviation-Logistics-Solutions"
                  onClick={handleLinkClick}
                >
                  Aviation Logistics Solutions
                </Link>
              </li>
              <li>
                <Link
                  to="/what-we-do?topic=Terms-Conditions"
                  onClick={handleLinkClick}
                >
                  Terms & Conditions
                </Link>
              </li>
            </ul>
          </li>
          <li style={{ hoverColor: "red" }}>
            <Link to="/shop" onClick={handleLinkClick}>
              Shop
            </Link>
          </li>
          {/* <li><Link to="/rfq" onClick={handleLinkClick}>RFQ</Link></li> */}
          <li>
            <Link to="/partssearch" onClick={handleLinkClick}>
              Parts Search
            </Link>
          </li>
          <li>
            <Link to="/careers" onClick={handleLinkClick}>
              Careers
            </Link>
          </li>
          <li>
            <Link to="/contact" onClick={handleLinkClick}>
              Contact
            </Link>
          </li>
          <li className="navbar-icon">
            <Link to="/login" onClick={handleLinkClick}>
                {
                  isMobileMenuOpen ? <p>Admin</p> :<img src={AdminIcon} alt="Custom Icon" className="custom-icon" />
                }
              
            </Link>
          </li>
        </ul>
      </nav>
    </>
  );
};

export default Navbar;
