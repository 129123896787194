import React, { useEffect, useState } from "react";
import { message } from "antd";
import "./contact.css";
import Img from "../../assets/images/solutions.jpg";
import Qrcode from "../../assets/images/QR-code.png";
import Repairs from "../../assets/images/img9.jpg";
import logoImg from "../../assets/images/log-no-bg.png";
import axios from "axios";
import API from "../../baseURL.config.json"
const ContactPage = () => {

  const [mail, setMail] = useState("");
  const [loading, setLoading] = useState(false);


  useEffect(() => {
    axios.get(`${API.devURL}/getContactEmail`)
      .then((res) => {
        setMail(res.data.contact_email)
      }).catch((err) => {
        message.error(err)
      })
  }, [])
  const handleSubmit = (e) => {
    e.preventDefault();
    const fullName = e.target.full_name.value;
    const email = e.target.email.value;
    const phone = e.target.phone.value;
    const company = e.target.company.value;
    const enquiryType = e.target.enquiry_type.value;
    const enquiryDescription = e.target.enquiry_description.value;

    if (
      !fullName ||
      !email ||
      !phone ||
      !enquiryDescription ||
      enquiryType === "Select enquiry type..."
    ) {
      message.error("Please fill all the mandatory fields");
      return;
    }

    setLoading(true);

    axios
      .post(`${API.devURL}/api/sendToContact`, {
        full_name: fullName,
        email,
        phone,
        company,
        enquiry_type: enquiryType,
        enquiry_description: enquiryDescription,
        add_mail: mail
      })
      .then((response) => {
        setLoading(false);
        console.log("first", response.data);
        message.success("Email Send Successfully");
        e.target.reset();
      })
      .catch((error) => {
        message.error(
          error.response?.data?.message ||
          "There was an error sending your enquiry. Please try again later."
        );
        setLoading(false);
      });
  };

  return (
    <div className="contact">
      {/* <div className="background-image"></div> */}

      <div className="hero-image">
        <img src={Repairs} alt="About Us Image" />
      </div>
      <div className="contact-container">
        <div className="contact-details">
          <h2>Arjuna Aerospace</h2>
          <p>Your trusted aircraft parts supplier from United Arab Emirates</p>
          <div className="contact-info">
            <p>
              <span role="img" aria-label="phone">
                📞
              </span>{" "}
              +971 527066365
            </p>
            {/* <p><span role="img" aria-label="phone">📞</span> +1 520 777 1575</p> */}
            <p>9:00 - 18:00 Monday - Friday | 9:00 - 13:00 Saturday</p>
          </div>
          <div className="contact-address">
            {/* <div className="contact-qr">
              <img src={Qrcode} alt="QR Code" />
            </div> */}
            <p>
              Z2-23, Sharjah Airport International Free Zone, PO Box 8000, Sharjah, UAE.
            </p>
          </div>
          <div className="certification">
            {/* <p>ISO 9001:2015 Certified</p> */}
            <img src={logoImg} alt="ASA Logo" className="asa-logo" />
          </div>
        </div>
        <div className="contact-form">
          <h3>SEND US AN EMAIL</h3>
          <p>Fill this form and our team will get in touch with you!</p>
          <form className="form-formcont" onSubmit={handleSubmit}>
            <div className="form-row">
              <input
                type="text"
                name="full_name"
                placeholder="Full Name"
                required
              />
              <input type="email" name="email" placeholder="Email" required />
              <input type="tel" name="phone" placeholder="Phone" required />
            </div>
            <input type="text" name="company" placeholder="Company" />
            <select name="enquiry_type" required>
              <option>Select enquiry type...</option>
              <option>General</option>
              <option>Parts Inquiry</option>
              <option>Support</option>
            </select>
            <textarea
              name="enquiry_description"
              placeholder="Enquiry"
              required
            ></textarea>
            <div className="captcha-container">
              <div className="g-recaptcha" data-sitekey="your-site-key"></div>
            </div>
            <button type="submit" disabled={loading}>
              {loading ? "Sending..." : "Send email"}
            </button>
          </form>
        </div>
      </div>
      {/* </div>
            </div> */}
    </div>
  );
};

export default ContactPage;
