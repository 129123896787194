import React, { useEffect, useState } from "react";
import "./shop.css";
import Video from "../../assets/videos/video.mp4";
import Img from "../../assets/images/ours-2.jpg";
import { Button, Form, Input, message, Select } from "antd";
import axios from "axios";
import TextArea from "antd/es/input/TextArea";
import API from "../../baseURL.config.json";
import { useLocation } from "react-router";
const RFQForm = () => {
  const [loading, setLoading] = useState(false);
  const [countryArray, setCountryArray] = useState([]);
  const [partNumberArray, setPartNumberArray] = useState([]);
  //const [state,setState] = useState("")
  const [mail,setMail] = useState("");

  const [formData, setFormData] = useState({
    fullName: "",
    email: "",
    phone: "",
    company: "",
    city: "",
    country: "",
    additionalInfo: "",
    pot_number: "",
  });
  const location = useLocation();
  const { label, value } = location.state || {};

  const updateStates = (updates) => {
    setFormData((prevState) => ({
      ...prevState,
      ...updates,
    }));
  };

  useEffect(() => {
   
    console.log("value",value)
    setFormData({
      pot_number:value
    })
    updateStates({
      pot_number:value
    })
    axios
      .get(`${API.devURL}/api/getAllCountries`)
      .then((res) => {
        console.log("Response", res.data);
        setCountryArray(res.data);
      })
      .catch((err) => {
        console.log("Err");
      });
    //get partno
    axios
      .get(`${API.devURL}/api/getExcel`)
       .then((res) => {
        console.log("Response Data22",res.data);
        //setCountryArray(res.data)
        const a = res.data[0].data.map((x,i) => {
          return {
            label:x[0]+" - "+x[1],
            value:x[0]
          }
        })
        console.log("excel",a)
        setPartNumberArray(a)
       }).catch((err) => {
        console.log("Err",err)
       })
 
        axios.get(`${API.devURL}/getSalesEmail`)
        .then((res) =>{
          setMail(res.data.sales_email)
        }).catch((err) => {
          message.error(err)
        })

  }, []);


  const handleSelectText = (e, name) => {
    const isPrintableKey = e.key.length == 1 && e.key !== 'Backspace';
      if (isPrintableKey) {
        setFormData(prevState => ({
          ...prevState,
          [name]  : e.target.value + e.key 
        }))
      }
  }

 

  const handleSubmit = () => {
    // e.preventDefault();
    const {
      fullName,
      email,
      phone,
      company,
      city,
      country,
      additionalInfo,
      pot_number,
    } = formData;

    // if (!fullName || !email || !phone || !city || !country || !additionalInfo) {
    //   message.error("Please fill all the mandatory fields");
    //   return;
    // }

    setLoading(true);

    axios
      .post(`${API.devURL}/api/sendToSales`, {
        full_name: formData.fullName,
        email: formData.email,
        phone: formData.phone,
        company: formData.company,
        pot_number: formData.pot_number, // Sending 'city' as 'pot_number' according to your API
        country: formData.country,
        additional_info: formData.additionalInfo,
        current_d: new Date().toISOString(),
        add_mail: mail
      })
      .then((response) => {
        setLoading(false);
        message.success(response.data.message || "Mail Sent Successfully");
        setFormData({
          fullName: "",
          email: "",
          phone: "",
          company: "",
          city: "",
          country: "",
          additionalInfo: "",
        });
      })
      .catch((error) => {
        setLoading(false);
        // message.error(
        //   error.response?.data?.message ||
        //     "There was an error sending the form. Please try again later."
        // );
      });
  };

  return (
    <div className="contact-wrapper">
      <div className="video-container">
        <video className="background-video" autoPlay loop muted>
          <source src={Video} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </div>
      <div className="rfq-form-wrapper">
        <div className="rfq-form-container">
          {/* <form onSubmit={handleSubmit} className="rfq-form"> */}
          <div className="rfq-form-header">
            <h2 className="rfq-form-title">SHOP</h2>
            {/* <button type="submit" className="rfq-form-submit">EMAIL</button> */}
          </div>

          <p className="rfq-form-description">
            Please fill up the RFQ form with the details of your requirement and
            we'll get back to you shortly.
          </p>

          <Form layout="vertical">
            <Form.Item
              label="Full Name*"
              style={{
                margin: 0,
              }}
            >
              <Input
                id="fullName"
                //name="fullName"
                value={formData.fullName}
                onChange={(e) =>
                  updateStates({
                    fullName: e.target.value,
                  })
                }
              />
            </Form.Item>
            <Form.Item
              label="Email*"
              style={{
                margin: 0,
              }}
            >
              <Input
                id="email"
                // name="email"
                value={formData.email}
                onChange={(e) =>
                  updateStates({
                    email: e.target.value,
                  })
                }
              />
            </Form.Item>
            <Form.Item
              label="Phone*"
              style={{
                margin: 0,
              }}
            >
              <Input
                id="phone"
                //name="phone"
                value={formData.phone}
                onChange={(e) =>
                  updateStates({
                    phone: e.target.value,
                  })
                }
              />
            </Form.Item>
            <Form.Item
              label="Company*"
              style={{
                margin: 0,
              }}
            >
              <Input
                id="company"
                // name="company"
                value={formData.company}
                onChange={(e) =>
                  updateStates({
                    company: e.target.value,
                  })
                }
              />
            </Form.Item>
            <Form.Item
              label="Country*"
              style={{
                margin: 0,
              }}
            >
              <Select
                options={countryArray}
                value={formData.country}
                onChange={(e) =>
                  updateStates({
                    country: e,
                  })
                }
                id="country"
                // name="country"
                style={{
                  width: "100%",
                }}
                showSearch
              />
            </Form.Item>
            <Form.Item
              label="Part Number*"
              style={{
                margin: 0,
              }}
            >
              <Select
                id="city"
                name="pot_number"
                value={formData.pot_number}
                onChange={(e) =>
                  updateStates({
                    pot_number: e,
                  })
                }
                options={partNumberArray}
                showSearch
                onInputKeyDown={(e) => {
                  handleSelectText(e,'pot_number')
                }}
                //onKeyDown={()}
                filterOption={false}
              />
            </Form.Item>
            <Form.Item
              label="Additional Info"
              style={{
                margin: 0,
              }}
            >
              <TextArea
                id="additionalInfo"
                //name="additionalInfo"
                value={formData.additionalInfo}
                onChange={(e) =>
                  updateStates({
                    additionalInfo: e.target.value,
                  })
                }
              />
            </Form.Item>
          </Form>
          <Form.Item
            style={{
              marginTop: "1%",
            }}
          >
            <Button type="primary" disabled={loading} onClick={handleSubmit}>
              {loading ? "Sending..." : "SEND"}
            </Button>
          </Form.Item>
          {/* </form> */}
        </div>
        <div className="form-image">
          <img src={Img} alt="Form Image" />
        </div>
      </div>
    </div>
  );
};

export default RFQForm;
