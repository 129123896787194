import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import "./aboutus.css";
import Repairs from "../../assets/images/about.jpeg";
import Repairs1 from "../../assets/images/img9.jpg";
import Repairs2 from "../../assets/images/img2.webp";
import New from "../../assets/images/aboutback.png";
import { Card, message } from "antd";
import axios from "axios";
import API from "../../baseURL.config.json";
const { Meta } = Card;

const AboutUs = () => {
  const location = useLocation();
  const [galleryData, setGalleryData] = useState([]);
  useEffect(() => {
    ///////////////////
    const fetchGallery = async () => {
      try {
        const response = await axios.get(`${API.devURL}/api/getGallery`);
        setGalleryData(response.data.data);
      } catch (error) {
        message.error("Failed to fetch gallery data");
      }
    };

    fetchGallery();

    //////////////////
    if (location.hash) {
      setTimeout(() => {
        const element = document.querySelector(location.hash);
        if (element) {
          element.scrollIntoView({ behavior: "smooth" });
        }
      }, 0);
    }
    const handleScroll = () => {
      const images = document.querySelectorAll(
        ".about-section .image img",
        ".about-section .image1 img"
      );
      const images1 = document.querySelectorAll(".about-section .image1 img");
      const scrollY = window.scrollY;
      const maxScroll = document.body.scrollHeight - window.innerHeight;
      const scrollRatio = scrollY / maxScroll;
      const moveDistance = scrollRatio * 50;

      images.forEach((image) => {
        image.style.transform = `translateY(${moveDistance}px)`;
      });
      images1.forEach((image) => {
        image.style.transform = `translateY(${moveDistance}px)`;
      });
    };

    window.addEventListener("scroll", handleScroll);

    return () => window.removeEventListener("scroll", handleScroll);
  }, [location.hash]);

  return (
    <div className="about-us-page">
      <div className="hero-image">
        <img src={New} alt="About Us Image" />
      </div>
      <div className="background-container">
        <div className="about-sections_all">
          <div className="about-section" id="our-company">
            <div className="content1">
              <h2>Our Company</h2>
              <p>
                Arjuna Aerospace is swiftly emerging as a premier distributor of
                aircraft spare parts, lubricants, ground support equipment, and
                more across Asia, Africa, and the Middle East. Our growth is
                attributed to the collective expertise of our team, which
                delivers innovative and value-driven solutions. Over time,
                Arjuna Aerospace has cultivated strategic partnerships with
                leading global companies and professionals, enabling us to offer
                round-the-clock( 24/7) services tailored to your aviation needs
                from our offices in UAE (Sharjah).
              </p>

              <p>
                {" "}
                Our primary focus encompasses defense, business, and general
                aviation, as well as helicopter operators and MROs supporting
                all fixed and rotor-wing aircraft. We provide comprehensive
                product support, including airframes, engines, tooling,
                avionics, instruments, brakes, wheels, consumables, lubricants,
                ground support equipment, landing gears, ratables, tires, and
                windows. Additionally, we excel in sourcing hard-to-find
                aerospace components.
              </p>

              <p>
                {" "}
                Our services extend to 24/7 AOG support, repairs, overhauls,
                exchanges, and logistics solutions, complemented by meticulous
                quality documentation.
              </p>

              <p>
                {" "}
                Our objective is to serve the aviation industry with
                unparalleled efficiency, expertise, and exceptional customer
                service. We are dedicated to aligning with our vision, mission,
                and values, and we aspire to be your preferred partner for all
                aerospace support needs.
              </p>
            </div>
            <div className="image1">
              <img src={Repairs} alt="Our Company" />
            </div>
          </div>
          <div className="about-section" id="vision-mission">
            <div className="image">
              <img src={Repairs1} alt="Vision & Mission" />
            </div>
            <div className="content">
              <h2>Vision & Mission</h2>
              <h3>Our Vision</h3>

              <p>
                {" "}
                Enhancing Customer delight by providing Realistic and Efficient
                Aviation Solutions.
              </p>

              <h3>Our Mission</h3>

              <p>
                {" "}
                Maintain and sustain ‘High Ethical Standards of Business’,
                ‘Exceed Clients' Expectations and strive for continual
                improvement.
              </p>
            </div>
          </div>
          <div className="about-section" id="management">
            <div className="content1">
              <h2>Management</h2>
              <p>
                "Surpassing customer expectations through transparency,
                accuracy, efficiency, and timeliness."
              </p>

              <p>
                {" "}
                At ARJUNA AEROSPACE, we are readily accessible—just an email or
                call away—to address all your aviation needs.
              </p>

              <h3> 24/7 AOG Support</h3>

              <p>
                {" "}
                We ensure the availability of high-quality products from
                approved vendors and adhere to stringent industry standards. Our
                commitment to quality is evidenced by the provision of essential
                certifications and documentation, including EASA Form 1, FAA
                8130-3, TCCA Form 1, and Manufacturer’s Certificates of
                Conformity (COC) for all parts.
              </p>

              <h3>Traceability</h3>

              <p>
                {" "}
                We maintain comprehensive traceability from the inception of our
                company, ensuring that every component and service meets our
                rigorous standards.
              </p>

              <h3> Value Beyond Price</h3>

              <p>
                {" "}
                We focus on delivering value through education and certainty
                rather than merely selling products. Our aim is to equip you
                with the knowledge and confidence you need to make informed
                decisions.
              </p>

              <h3>Real-Time and Accurate Information</h3>

              <p>
                {" "}
                We are dedicated to providing real-time, accurate information to
                facilitate smooth operations and decision-making.
              </p>

              <h3>Prompt Dispatch</h3>

              <p>
                {" "}
                We offer same-day dispatch from our stock and typically deliver
                non-stock items within three working days.
              </p>
            </div>
            <div className="image1">
              <img src={Repairs2} alt="Management" />
            </div>
          </div>
          <div className="gallery-section" id="gallery">
            <h2>Gallery</h2>
            <div className="about-section">
              <div className="gallery-grid">
                {galleryData &&
                  galleryData.map((x, i) => {
                    return (
                      <Card
                        hoverable
                        style={{
                          width: 240,

                        }}
                        cover={<img alt={x.product_name} src={x.image_url} style={{
                          width: "100%",
                          height: 300
                        }} />}
                      >
                        <Meta
                          title={x.product_name}
                          description={x.product_description}
                        />
                      </Card>
                    );
                  })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
