import React, { useEffect, useState } from "react";
import {
  Table,
  Button,
  Modal,
  Form,
  Input,
  message,
  Tabs,
  Tooltip,
  Row,
  Select,
} from "antd";
import axios from "axios";
import API from "../baseURL.config.json";
import Dropzone from "react-dropzone";
import * as XLSX from "xlsx";
import CryptoJS from "crypto-js";
import { useNavigate } from "react-router";

const Gallery = () => {
  const [galleryData, setGalleryData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [editData, setEditData] = useState(null);
  const [dataSource2, setDataSource2] = useState([]);
  const [form] = Form.useForm();
  const [formsData, setFormsData] = useState({
    product_name: "",
    product_description: "",
  });
  const navigate = useNavigate()
  const [selectedFile, setSelectedFile] = useState(null);
  const [excelFile, setExcelFile] = useState(null);
  const [imagePreview, setImagePreview] = useState(null);
  const [pagination, setPagination] = useState({ current: 1, pageSize: 5 });
  const [userData, setUserData] = useState([]);
  const [openUserModal, setOpenUserModal] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [careerDataSource,setCareerDataSource] = useState([])
  const [openCareerModal, setOpenCareerModal] = useState(false);
  const [openCareerEditModal, setOpenCareerEditModal] = useState(false);
  const [newUser, setNewUser] = useState({
    username: "",
    password: "",
    email: "",
    role: "",
    id: "",
  });
  const [newUser2, setNewUser2] = useState({
    username: "",
    password: "",
    email: "",
    role: "",
  });
  const [newJob, setNewJob] = useState({
    sno:"",
    job_title:"",
    job_description:"",
    job_location:"",
    job_size:"",
    job_salary_range:"",
  });
  const [newJob2, setNewJob2] = useState({
    sno:"",
    job_title:"",
    job_description:"",
    job_location:"",
    job_size:"",
    job_salary_range:"",
  });

  const [careers_mail,setCareers_mail] = useState("");
  const [contact_mail,setContact_mail] = useState("");
  const [sales_mail,setSales_mail] = useState("");
  const [general_mail,setGeneral_mail] = useState("");
  const [mobile_no1,setMobileNo1] = useState("");
  const [mobile_no2,setMobileNo2] = useState("");
  const CHUNK_SIZE = 2000;

  const updateNewUser = (updates) => {
    setNewUser((prevState) => ({
      ...prevState,
      ...updates,
    }));
  };
  const updateNewUser2 = (updates) => {
    setNewUser2((prevState) => ({
      ...prevState,
      ...updates,
    }));
  };
  const updateCareer = (updates) => {
    setNewJob((prevState) => ({
      ...prevState,
      ...updates,
    }));
  };
  const updateCareer2 = (updates) => {
    setNewJob2((prevState) => ({
      ...prevState,
      ...updates,
    }));
  };

  function encryptPassword(password) {
    const secretKey = API.secretKey; // Must match backend secret key
    const encryptedPassword = CryptoJS.AES.encrypt(
      password,
      secretKey
    ).toString();
    return encryptedPassword;
  }

  function decryptPassword(encryptedPassword) {
    const secretKey = API.secretKey;
    const bytes = CryptoJS.AES.decrypt(encryptedPassword, secretKey);
    const originalPassword = bytes.toString(CryptoJS.enc.Utf8);
    return originalPassword;
  }

  const handleDrop = (acceptedFiles) => {
    const file = acceptedFiles[0];
  
    const reader = new FileReader();
    reader.onload = (e) => {
      const data = e.target.result;
      const workbook = XLSX.read(data, { type: "binary" });
      const sheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[sheetName];
      const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
  
      const sendChunks = async () => {
        for (let i = 0; i < jsonData.length; i += CHUNK_SIZE) {
          const chunk = jsonData.slice(i, i + CHUNK_SIZE);
          try {
            const response = await fetch(`${API.devURL}/api/excel`, {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify(chunk),
            });
            const data = await response.json();
            console.log("Chunk upload success:", data);
            message.success("Excel File Uploaded Successfully")
          } catch (error) {
            console.error("Chunk upload error:", error);
          }
        }
      };
  
      sendChunks();
    };
    reader.readAsBinaryString(file);
  };



  useEffect(() => {

    axios.get(`${API.devURL}/getEmails`)
    .then((res) => {
      console.log("res",res.data)
      setCareers_mail(res.data.careers_email);
      setContact_mail(res.data.contact_email);
      setSales_mail(res.data.sales_email);
      setGeneral_mail(res.data.general_email);
      setMobileNo1(res.data.mobile_no_1);
      setMobileNo2(res.data.mobile_no_2);
    }).catch((err) => {
      console.log("Error",err)
    });
    axios
      .get(`${API.devURL}/api/getAllSales`)
      .then((res) => {
        console.log("res", res.data);
        //  setDataSource2()
        const a = res.data.map((x, i) => {
          return {
            key: i + 1,
            fullname: x.full_name,
            partnumber: x.part_number,
            email: x.email,
            phone: x.phone,
            company: x.company,
            country: x.country,
            date: x.current_d,
            additional_info: x.additional_info
          };
        });
        setDataSource2(a);
      })
      .catch((err) => {
        console.log("err", err);
      });
      fetchGallery();
    fetchUsers();
    fetchCareers();
    //fetchEmails();
        ///////////protected logic here
  
    //////////////////////
 
  }, []);

  useEffect(() => {
    // const disableSwipe = (e) => {
    //   e.preventDefault();
    // };
  
    // window.addEventListener('touchmove', disableSwipe, { passive: false });
  
    // return () => {
    //   window.removeEventListener('touchmove', disableSwipe);
    // };

  if(!localStorage.getItem("token")){
    navigate("/login")
  }
  },[localStorage.getItem("token")])

  const fetchGallery = async () => {
    setLoading(true);
    try {
      const response = await axios.get(`${API.devURL}/api/getGallery`);
      setGalleryData(response.data.data);
      setPagination({ ...pagination, total: response.data.total });
    } catch (error) {
      message.error("Failed to fetch gallery data");
    } finally {
      setLoading(false);
    }
  };

  const fetchUsers = () => {
    axios
      .get(`${API.devURL}/users`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        console.log("Resposne", res.data);
        const a = res.data.map((x, i) => {
          return {
            sno: x.id,
            username: x.username,
            email: x.email,
            role: x.role,
            password: x.password,
          };
        });
        setUserData(a);
      })
      .catch((err) => {
        console.log("Error", err);
      });
  };


  const fetchCareers = () => {
    axios
      .get(`${API.devURL}/getJobs`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        console.log("Career", res.data);
        const a = res.data.map((x, i) => {
          return {
            sno: x.id,
            job_description: x.job_description,
            job_location: x.job_location,
            job_salary_range: x.job_salary_range,
            job_size: x.job_size,
            job_title: x.job_title,
          };
        });
        setCareerDataSource(a)
      })
      .catch((err) => {
        console.log("Error", err);
      });
  };

  const columns2 = [
    {
      title: "FullName",
      dataIndex: "fullname",
      key: "fullname",
    },
    {
      title: "Part Number",
      dataIndex: "partnumber",
      key: "partnumber",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Phone",
      dataIndex: "phone",
      key: "phone",
    },
    {
      title: "Company",
      dataIndex: "company",
      key: "company",
    },
    {
      title: "Country",
      dataIndex: "country",
      key: "country",
    },
    {
      title: "Additional Information",
      dataIndex: "additional_info",
      key: "additional_info",
    },
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
    },
  ];

  const columns3 = [
    {
      title: "S.No",
      dataIndex: "sno",
      key: "sno",
    },
    {
      title: "Username",
      dataIndex: "username",
      key: "username",
    },
    {
      title: "Password",
      dataIndex: "password",
      key: "password",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Role",
      dataIndex: "role",
      key: "role",
    },
    {
      title: "Actions",
      key: "action",
      render: (text, record) => (
        <>
          <Button type="link" onClick={() => handleEdit2(record)}>
            Edit
          </Button>
           {
            record.role == "SUPER_USER" ? 
            null :
             <Button type="link" danger onClick={() => handleDelete2(record)}>
            Delete
          </Button>
           }
        </>
      ),
    },
  ];

  const columns4 = [
    {
      title: "S.No",
      dataIndex: "sno",
      key: "sno",
    },
    {
      title: "Job Title",
      dataIndex: "job_title",
      key: "job_title",
    },
    {
      title: "Job Description",
      dataIndex: "job_description",
      key: "job_description",
    },
    {
      title: "Job Location",
      dataIndex: "job_location",
      key: "job_location",
    },
    {
      title: "Required",
      dataIndex: "job_size",
      key: "job_size",
    },
    {
      title: "Salary",
      dataIndex: "job_salary_range",
      key: "job_salary_range",
    },
    {
      title: "Actions",
      key: "action",
      render: (text, record) => (
        <>
          <Button
            type="link"
            onClick={() => handleEdit4(record)}
          >
            Edit
          </Button>
          <Button
            type="link"
            danger
            onClick={() => handleDelete4(record)}
          >
            Delete
          </Button>
        </>
      ),
    },
  ];

  const handleSubmit = async () => {
    const { product_name, product_description } = formsData;
    const current_date = new Date().toISOString().slice(0, 10);
    const formData = new FormData();

    if (selectedFile) {
      formData.append("image", selectedFile);
    }

    formData.append("product_name", product_name);
    formData.append("product_description", product_description);
    formData.append("current_date", current_date);

    try {
      if (editData) {
        const response = await axios.put(
          `${API.devURL}/api/updateGallery/${editData.prod_id}`,
          formData,
          {
            headers: { "Content-Type": "multipart/form-data" },
          }
        );

        if (response.status === 200) {
          message.success("Data updated successfully");
        } else {
          throw new Error("Update failed");
        }
      } else {
        const response = await axios.post(`${API.devURL}/upload`, formData, {
          headers: { "Content-Type": "multipart/form-data" },
        });
        if (response.status === 200) {
          message.success("Image uploaded successfully");
        } else {
          throw new Error("Image upload failed");
        }
      }
      fetchGallery();
    } catch (error) {
      console.error("Error uploading or updating image:", error);
      message.error("Error processing request");
    } finally {
      closeModal();
    }
  };

  const handleDelete = async (data) => {
    try {
      await axios.delete(`${API.devURL}/api/deleteGallery/${data.prod_id}`);
      message.success("Deleted successfully");
      fetchGallery();
    } catch (error) {
      message.error("Failed to delete");
    }
  };

  const handleEdit = (data) => {
    setEditData(data);
    form.setFieldsValue({
      product_name: data.product_name,
      product_description: data.product_description,
    });
    setFormsData({
      product_name: data.product_name,
      product_description: data.product_description,
    });
    setImagePreview(data.image_url);
    setVisible(true);
  };

  const openModal = () => {
    setEditData(null);
    form.resetFields();
    setVisible(true);
    setImagePreview(null);
  };

  const closeModal = () => {
    setVisible(false);
    setSelectedFile(null);
    setImagePreview(null);
  };

  const handleTableChange = (pagination) => {
    setPagination(pagination);
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setSelectedFile(file);
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        setImagePreview(e.target.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleOk2 = () => {
    // setOpenUserModal(false);
    axios
      .post(
        `${API.devURL}/register`,
        {
          username: newUser.username,
          password: newUser.password,
          role: newUser.role,
          email: newUser.email,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        console.log("Res", res.data);
        message.success(`User created successfully`).then(() => {
          setOpenUserModal(false);
        });
        fetchUsers();
      })
      .catch((err) => {
        console.log("err", err);
        message.error(err);
      });
  };
  const handleCancel2 = () => {
    setOpenUserModal(false);
  };

  const handleOk3 = () => {
    // setOpenUserModal(false);
    console.log("user2", newUser2);
    axios
      .put(
        `${API.devURL}/editusers/${newUser.id}`,
        {
          username: newUser2.username,
          password: newUser2.password,
          role: newUser2.role,
          email: newUser2.email,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        console.log("Res", res.data);
        message.success(`User Edited successfully`);
        setOpenEditModal(false);
        fetchUsers();
      })
      .catch((err) => {
        console.log("err", err);
        message.error(err);
      });
  };

  /////////
  const handleOk4 = () => {
    // setOpenUserModal(false);
    console.log("career", newJob);
    axios
      .post(
        `${API.devURL}/createJobs`,
        {
          job_description: newJob.job_description,
          job_title: newJob.job_title,
          job_location: newJob.job_location,
          job_size: newJob.job_size,
          job_salary_range: newJob.job_salary_range 
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        console.log("Res", res.data);
        message.success(`Job Created successfully`);
        // setOpenEditModal(false);
        // fetchUsers();
        setOpenCareerModal(false)
      })
      .catch((err) => {
        console.log("err", err);
        message.error(err);
      });
  };

  const handleOk5 = () => {
    // setOpenUserModal(false);
    console.log("career", newJob2);
    axios
      .put(
        `${API.devURL}/updatejobs/${newJob2.sno}`,
        {
          job_description: newJob2.job_description,
          job_title: newJob2.job_title,
          job_location: newJob2.job_location,
          job_size: newJob2.job_size,
          job_salary_range: newJob2.job_salary_range 
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        console.log("Res", res.data);
        message.success(`Job Edited successfully`);
        // setOpenEditModal(false);
        fetchCareers();
        setOpenCareerEditModal(false)
      })
      .catch((err) => {
        console.log("err", err);
        message.error(err);
      });
  };
  const handleCancel3 = () => {
    setOpenEditModal(false);
  };
  const handleCancel4 = () => {
    setOpenCareerModal(false);
  };
  const handleCancel5 = () => {
    setOpenCareerEditModal(false);
  };

  const handleDelete4 = async (data) => {
    try {
      await axios.delete(`${API.devURL}/deletejobs/${data.sno}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
      message.success("Deleted successfully");
      fetchCareers();
    } catch (error) {
      message.error("Failed to delete");
    }
    console.log("2", data);
  };

  const handleEdit2 = async (data) => {
    setOpenEditModal(true);
    updateNewUser({
      id: data.sno,
    });
    updateNewUser2({
      username: data.username,
      password: decryptPassword(data.password),
      email: data.email,
      role: data.role,
    });
    console.log("r", data);
  };


  
  const handleEdit4 = async (data) => {
    console.log(":da6a",data)
    setOpenCareerEditModal(true);
    updateCareer2({
      sno: data.sno,
    });
    updateCareer2({
      job_title:data.job_title,
      job_description:data.job_description,
      job_location:data.job_location,
      job_size:data.job_size,
      job_salary_range:data.job_salary_range,
    });
    console.log("r", data);
  };

  const handleDelete2 = async (data) => {
    try {
      await axios.delete(`${API.devURL}/deleteusers/${data.sno}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
      message.success("Deleted successfully");
      fetchUsers();
    } catch (error) {
      message.error("Failed to delete");
    }
    console.log("2", data);
  };


  const columns = [
    {
      title: "Product Name",
      dataIndex: "product_name",
      key: "product_name",
      render: (text) => <span style={{ fontWeight: "bold" }}>{text}</span>,
    },
    {
      title: "Description",
      dataIndex: "product_description",
      key: "product_description",
      render: (text) => (
        <Tooltip title={text} placement="topLeft">
          {" "}
          <p
            style={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              maxWidth: "200px",
              margin: 0,
            }}
          >
            {text}
          </p>
        </Tooltip>
      ),
    },
    {
      title: "Image",
      dataIndex: "image_url",
      key: "image_url",
      render: (url) =>
        url ? (
          <img
            src={url}
            alt="product"
            style={{
              width: "80px",
              height: "80px",
              objectFit: "cover",
              borderRadius: "8px",
              boxShadow: "0 2px 8px rgba(0, 0, 0, 0.1)",
            }}
          />
        ) : (
          <span style={{ color: "#ccc" }}>No Image</span>
        ),
    },
    {
      title: "Actions",
      key: "action",
      render: (text, record) => (
        <>
          <Button type="link" onClick={() => handleEdit(record)}>
            Edit
          </Button>
          <Button type="link" danger onClick={() => handleDelete(record)}>
            Delete
          </Button>
        </>
      ),
    },
  ];

  const updateMails2 = () => {
    axios.put(`${API.devURL}/updateEmails`,{
      careers_email:careers_mail,
      contact_email:contact_mail,
      sales_email: sales_mail,
      general_mail: general_mail,
      mobile_no1:mobile_no1,
      mobile_no2:mobile_no2
    }).then((res) => {
      console.log("res",res.data)
      message.success("Data Updated Successfully")
    }).catch((err) => {
      console.log("error",err);
      message.error(err)
    })
  }
  const items =
    localStorage.getItem("role") == "SUPER_USER"
      ? [
          {
            key: "1",
            label: "Gallery",
            children: (
              <div style={{
                width: "100%",
                height: "100vh",
              }}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    marginRight: "1rem",
                    
                  }}
                >
                  <Button type="primary" onClick={openModal}>
                    Add New
                  </Button>
                </div>
                <Table
                  columns={columns}
                  dataSource={galleryData}
                  rowKey="prod_id"
                  pagination={pagination}
                  loading={loading}
                  onChange={handleTableChange}
                  bordered
                  style={{ marginTop: "16px", width:'100%' }}
                />
              </div>
            ),
          },
          {
            key: "2",
            label: "Part Number",
            children: (
              <div
                style={{
                  width: "90%",
                  height: "40vh",
                  textAlign: "center",
                  borderRadius: "12px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  backgroundColor: "#d1e8fc",
                  position: "relative",
                  left: "5%",
                  marginTop: "2%",
                  marginBottom: "2%",
                }}
              >
                <Dropzone onDrop={handleDrop}>
                  {({ getRootProps, getInputProps }) => (
                    <section>
                      <div {...getRootProps()}>
                        <input {...getInputProps()} />
                        <p>
                          Drag and drop your Excel file here, or click to select
                          one
                        </p>
                      </div>
                    </section>
                  )}
                </Dropzone>
              </div>
            ),
          },
          {
            key: "3",
            label: "Sales Data",
            children: <Table dataSource={dataSource2} columns={columns2} />,
          },
          {
            key: "4",
            label: "User Management",
            children: (
              <div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  <Button type="primary" onClick={() => setOpenUserModal(true)}>
                    Create New User +
                  </Button>
                </div>
                <div>
                  <Table columns={columns3} dataSource={userData} />
                </div>
              </div>
            ),
          },
          {
            key: "5",
            label: "Career Management",
            children: (
              <div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  <Button type="primary" 
                   onClick={() => setOpenCareerModal(true)}
                  >
                    Create New Job +
                  </Button>
                </div>
                <div>
                  <Table columns={columns4} 
                   dataSource={careerDataSource} 
                  />
                </div>
              </div>
            ),
          },
          {
            key: "6",
            label: "Mail Management",
            children: (
              <Form layout="vertical" style={{
                width:'40%',
                position:'relative',
                left:'2%'
              }}>
                <Form.Item>
                  <h1>Change Mails Here</h1>
                </Form.Item>
                <Form.Item label="Sales Mail">
                  <Input value={sales_mail} onChange={(e) => setSales_mail(e.target.value)}/>
                </Form.Item>
                <Form.Item label="Contact Mail">
                  <Input value={contact_mail} onChange={(e) => setContact_mail(e.target.value)}/>
                </Form.Item>
                <Form.Item label="Careers Mail">
                  <Input value={careers_mail} onChange={(e) => setCareers_mail(e.target.value)}/>
                </Form.Item>
                {/* new */}
                <Form.Item label="General Mail">
                  <Input value={general_mail} onChange={(e) => setGeneral_mail(e.target.value)}/>
                </Form.Item>
                <Form.Item label="Mobile No">
                  <Input value={mobile_no1} onChange={(e) => setMobileNo1(e.target.value)}/>
                </Form.Item>
                <Form.Item label="Secondary Mobile No">
                <Input value={mobile_no2} onChange={(e) => setMobileNo2(e.target.value)}/>
                </Form.Item>
                <Form.Item>
                  <Button type='primary' onClick={updateMails2}>Update</Button>
                </Form.Item>
              </Form>
            ),
          },
        ]
      : [] || localStorage.getItem("role") == "USER"
      ? [
          {
            key: "1",
            label: "Sales Data",
            children: <Table dataSource={dataSource2} columns={columns2} />,
          },
        ]
      : [];

      const handleLogout = () => {
        localStorage.removeItem('token'); // Remove token from local storage
        window.location.href = '/login';  // Redirect to login page
      };
      
  return (
    <div>
      <div style={{
        display:'flex',
        justifyContent:'flex-end',
        marginTop:'1em',
        marginBottom:'1em'
      }}><Button onClick={handleLogout} type="primary" >Logout</Button></div>
      <Tabs defaultActiveKey="1" items={items} style={{
        width:"100%"
      }} />
      <Modal
        title={editData ? "Edit Product" : "Add New Product"}
        onCancel={closeModal}
        footer={null}
        open={visible}
      >
        <Form form={form} layout="vertical" onFinish={handleSubmit}>
          <Form.Item
            name="product_name"
            label="Product Name"
            rules={[
              { required: true, message: "Please enter the product name" },
            ]}
          >
            <Input
              value={formsData.product_name}
              onChange={(e) =>
                setFormsData({ ...formsData, product_name: e.target.value })
              }
            />
          </Form.Item>
          <Form.Item
            name="product_description"
            label="Product Description"
            rules={[
              {
                required: true,
                message: "Please enter the product description",
              },
            ]}
          >
            <Input.TextArea
              rows={4}
              value={formsData.product_description}
              onChange={(e) =>
                setFormsData({
                  ...formsData,
                  product_description: e.target.value,
                })
              }
            />
          </Form.Item>
          {/* <Form.Item
            name="image"
            label="Upload Image"
            rules={[{ required: !editData, message: "Please upload an image" }]}
          >
            <Input
              type="file"
              accept="image/*"
              onChange={(e) => setSelectedFile(e.target.files[0])}
            />
          </Form.Item> */}
          <Form.Item name="image" label="Upload Image">
            {imagePreview && (
              <img
                src={imagePreview}
                alt="Current"
                style={{
                  width: "100px",
                  height: "100px",
                  objectFit: "cover",
                  marginBottom: "10px",
                }}
              />
            )}
            <Input type="file" accept="image/*" onChange={handleFileChange} />
          </Form.Item>
          <Button type="primary" htmlType="submit" loading={loading}>
            {editData ? "Update" : "Create"}
          </Button>
        </Form>
      </Modal>
      <Modal
        open={openUserModal}
        onClose={handleCancel2}
        footer={[
          <Button key="back" onClick={handleCancel2}>
            Close
          </Button>,
          <Button key="submit" type="primary" onClick={handleOk2}>
            Submit
          </Button>,
        ]}
      >
        <Form layout="vertical">
          <Form.Item>
            <p
              style={{
                fontSize: "2em",
              }}
            >
              Create User
            </p>
          </Form.Item>
          <Form.Item label="Username">
            <Input
              value={newUser.username}
              onChange={(e) =>
                updateNewUser({
                  username: e.target.value,
                })
              }
            />
          </Form.Item>
          <Form.Item label="Password">
            <Input.Password
              value={newUser.password}
              onChange={(e) =>
                updateNewUser({
                  password: e.target.value,
                })
              }
            />
          </Form.Item>
          <Form.Item label="Email">
            <Input
              value={newUser.email}
              onChange={(e) =>
                updateNewUser({
                  email: e.target.value,
                })
              }
            />
          </Form.Item>
          <Form.Item label="Role">
            <Select
              options={[
                {
                  label: "User",
                  value: "USER",
                },
                { label: "Super User", value: "SUPER_USER" },
              ]}
              value={newUser.role}
              onChange={(e) =>
                updateNewUser({
                  role: e,
                })
              }
            />
          </Form.Item>
        </Form>
      </Modal>
      {/* edit 2 modal */}
      <Modal
        open={openEditModal}
        onClose={handleCancel3}
        footer={[
          <Button key="back" onClick={handleCancel3}>
            Close
          </Button>,
          <Button key="submit" type="primary" onClick={handleOk3}>
            Submit
          </Button>,
        ]}
      >
        <Form layout="vertical">
          <Form.Item>
            <p
              style={{
                fontSize: "2em",
              }}
            >
              Edit User
            </p>
          </Form.Item>
          <Form.Item label="Username">
            <Input
              value={newUser2.username}
              onChange={(e) =>
                updateNewUser2({
                  username: e.target.value,
                })
              }
            />
          </Form.Item>
          <Form.Item label="Password">
            <Input.Password
              value={newUser2.password}
              onChange={(e) =>
                updateNewUser2({
                  password: e.target.value,
                })
              }
            />
          </Form.Item>
          <Form.Item label="Email">
            <Input
              value={newUser2.email}
              onChange={(e) =>
                updateNewUser2({
                  email: e.target.value,
                })
              }
            />
          </Form.Item>
          <Form.Item label="Role">
            <Select
              options={[
                {
                  label: "User",
                  value: "USER",
                },
                { label: "Super User", value: "SUPER_USER" },
              ]}
              value={newUser2.role}
              onChange={(e) =>
                updateNewUser2({
                  role: e,
                })
              }
            />
          </Form.Item>
        </Form>
      </Modal>
      {/* career modal */}
      <Modal
        open={openCareerModal}
        onClose={handleCancel4}
        footer={[
          <Button key="back" onClick={handleCancel4}>
            Close
          </Button>,
          <Button key="submit" type="primary" onClick={handleOk4}>
            Submit
          </Button>,
        ]}
      >
        <Form layout="vertical">
          <Form.Item>
            <p
              style={{
                fontSize: "2em",
              }}
            >
              Create Job
            </p>
          </Form.Item>
          <Form.Item label="Job Title">
            <Input
              value={newJob.job_title}
              onChange={(e) =>
                updateCareer({
                  job_title: e.target.value,
                })
              }
            />
          </Form.Item>
          <Form.Item label="Job Description">
            <Input
              value={newJob.job_description}
              onChange={(e) =>
                updateCareer({
                  job_description: e.target.value,
                })
              }
            />
          </Form.Item>
          <Form.Item label="Job Location">
            <Input
              value={newJob.job_location}
              onChange={(e) =>
                updateCareer({
                  job_location: e.target.value,
                })
              }
            />
          </Form.Item>
          <Form.Item label="Job Size">
            <Input
              value={newJob.job_size}
              onChange={(e) =>
                updateCareer({
                  job_size: e.target.value,
                })
              }
            />
          </Form.Item>
          <Form.Item label="Job Salary Range">
            <Input
              value={newJob.job_salary_range}
              onChange={(e) =>
                updateCareer({
                  job_salary_range: e.target.value,
                })
              }
            />
          </Form.Item>
        </Form>
      </Modal>

      {/* career edit modal */}
      <Modal
        open={openCareerEditModal}
        onClose={handleCancel5}
        footer={[
          <Button key="back" onClick={handleCancel5}>
            Close
          </Button>,
          <Button key="submit" type="primary" onClick={handleOk5}>
            Submit
          </Button>,
        ]}
      >
        <Form layout="vertical">
          <Form.Item>
            <p
              style={{
                fontSize: "2em",
              }}
            >
              Edit Job
            </p>
          </Form.Item>
          <Form.Item label="Job Title">
            <Input
              value={newJob2.job_title}
              onChange={(e) =>
                updateCareer2({
                  job_title: e.target.value,
                })
              }
            />
          </Form.Item>
          <Form.Item label="Job Description">
            <Input
              value={newJob2.job_description}
              onChange={(e) =>
                updateCareer2({
                  job_description: e.target.value,
                })
              }
            />
          </Form.Item>
          <Form.Item label="Job Location">
            <Input
              value={newJob2.job_location}
              onChange={(e) =>
                updateCareer2({
                  job_location: e.target.value,
                })
              }
            />
          </Form.Item>
          <Form.Item label="Job Size">
            <Input
              value={newJob2.job_size}
              onChange={(e) =>
                updateCareer2({
                  job_size: e.target.value,
                })
              }
            />
          </Form.Item>
          <Form.Item label="Job Salary Range">
            <Input
              value={newJob2.job_salary_range}
              onChange={(e) =>
                updateCareer2({
                  job_salary_range: e.target.value,
                })
              }
            />
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default Gallery;
