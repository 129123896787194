


import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import './footer.css';
import { message } from 'antd';
import axios from 'axios';
import API from "../baseURL.config.json";
const Footer = () => {
    const [mobile_no1, setmobile_no1] = useState("");
    const [mobile_no2, setmobile_no2] = useState("");
    const [mail, setMail] = useState("");
    useEffect(() => {
        //email
    axios
    .get(`${API.devURL}/getGeneralEmail`)
    .then((res) => {
      setMail(res.data.general_email);
    })
    .catch((err) => {
      message.error(err);
    });
  //mobile
  axios
    .get(`${API.devURL}/getMobileNo1`)
    .then((res) => {
      setmobile_no1(res.data.mobile_no_1);
    })
    .catch((err) => {
      message.error(err);
    });
  //mobile2
  axios
    .get(`${API.devURL}/getMobileNo2`)
    .then((res) => {
      setmobile_no2(res.data.mobile_no_2);
    })
    .catch((err) => {
      message.error(err);
    });
    },[])
    return (
        <footer className='footer1'>
            <div className='footer-container'>
                <div className='footer-column'>
                    <h3 className='footer-heading'>Arjuna Aviation</h3>
                    <ul className='footer-list'>
                        <li ><span className="material-icons"  >chevron_right</span><Link to="/" className='footer-link'>Home</Link></li>
                        <li ><span className="material-icons" >chevron_right</span><Link to="/about-us" className='footer-link'>About Us</Link></li>
                        <li ><span className="material-icons" >chevron_right</span><Link to="/shop" className='footer-link'>Shop</Link></li>
                        {/* <li ><span className="material-icons" >chevron_right</span><Link to="/rfq" className='footer-link'>RFQ</Link></li> */}
                        <li ><span className="material-icons" >chevron_right</span><Link to="/partssearch" className='footer-link'>Parts Search</Link></li>
                        <li ><span className="material-icons" >chevron_right</span><Link to="/careers" className='footer-link'>Careers</Link></li>



                        {/* <li><span className="material-icons" style={arrowStyle}>chevron_right</span><Link to="/aviation-consultancy" style={linkStyle}>Aviation Consultancy</Link></li>
                        <li><span className="material-icons" style={arrowStyle}>chevron_right</span><Link to="/aircraft-maintenance" style={linkStyle}>Aircraft Maintenance</Link></li>
                        <li><span className="material-icons" style={arrowStyle}>chevron_right</span><Link to="/faqs" style={linkStyle}>FAQs</Link></li> */}
                    </ul>
                </div>
                {/* <div style={footerColumnStyle}>
                    <h3 style={footerHeadingStyle}>Academy</h3>
                    <ul style={footerListStyle}>
                        <li><span className="material-icons" style={arrowStyle}>chevron_right</span><Link to="/civil-aviation" style={linkStyle}>Ab Initio (Civil Aviation)</Link></li>
                        <li><span className="material-icons" style={arrowStyle}>chevron_right</span><Link to="/advance-pilot" style={linkStyle}>Advance Pilot Programs</Link></li>
                        <li><span className="material-icons" style={arrowStyle}>chevron_right</span><Link to="/helicopter-courses" style={linkStyle}>Helicopter Pilot Courses</Link></li>
                        <li><span className="material-icons" style={arrowStyle}>chevron_right</span><Link to="/recreational-courses" style={linkStyle}>Recreational Courses</Link></li>
                    </ul>
                </div> */}
                <div className='footer-column'>
                    <h3 className='footer-heading'>Our Services</h3>
                    <ul className='footer-list'>
                        <li ><span className="material-icons" >chevron_right</span><Link to="/what-we-do?topic=Commercial-Aircraft-Support" className='footer-link'>Commercial Aircraft Support</Link></li>
                        <li ><span className="material-icons" >chevron_right</span><Link to="/what-we-do?topic=General-Aircraft-Support" className='footer-link'>General Aircraft Support</Link></li>
                        <li ><span className="material-icons" >chevron_right</span><Link to="/what-we-do?topic=Repair-and-Maintenance-Services" className='footer-link'>Repair and Maintenance Services</Link></li>
                        <li ><span className="material-icons" >chevron_right</span><Link to="/what-we-do?topic=Aviation-Logistics-Solutions" className='footer-link'>Aviation Logistics Solutions</Link></li>
                        <li ><span className="material-icons" >chevron_right</span><Link to="/what-we-do?topic=Terms-Conditions" className='footer-link'>Terms & Conditions</Link></li>
                    </ul>
                </div>
                <div className='footer-column'>
                    <h3 className='footer-heading'>Contact Us</h3>
                    <p className='footer-contact'>Feel free to call/mail us for any further queries:</p>
                    <p className='footer-contact'>{mobile_no1}</p>
                    <p className='footer-contact'>{mobile_no2}</p>
                    <p className='footer-contact'>{mail}</p>
                </div>
            </div>
            <footer className="footer">
                <p>&copy; Arjuna Aerospace Website 2024</p>
            </footer>
        </footer>
    );
};

// const footerStyle = {
//     backgroundColor: '#0b2e81',
//     color: '#fff',
//     padding: '40px 20px',
//     textAlign: 'left',
// };

// const footerContainerStyle = {
//     display: 'flex',
//     justifyContent: 'space-around',
// };

// const footerColumnStyle = {
//     flex: '1',
//     margin: '0 20px',
// };

// const footerHeadingStyle = {
//     color: '#fff',
//     marginBottom: '20px',
//     borderBottom: '3px solid gold',
//     display: 'inline-block',
//     paddingBottom: '5px',
// };

// const footerListStyle = {
//     listStyleType: 'none',
//     padding: 0,
// };

// const liststyle = {
//     display: 'flex',
// }

// const linkStyle = {
//     textDecoration: 'none',
//     color: '#ffb400',

// };

// const arrowStyle = {
//     marginRight: '10px',
//     color: '#ffb400',
// };

// const contactStyle = {
//     marginBottom: '10px',
// };



export default Footer;
