/* eslint-disable jsx-a11y/heading-has-content */
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import "./homepage.css";
import Video from "../../assets/videos/home_vd.mp4";

import Productimg from "../../assets/images/product-support.jpeg";
import Aircraftnimg from "../../assets/images/aircraft-support.jpg";
import Repairsimg from "../../assets/images/repair-main.jpg";
import Solutionimg from "../../assets/images/about.jpeg";
import { AutoComplete, Button, Carousel, Input } from "antd";
import API from "../../baseURL.config.json";
import axios from "axios";
import image1 from "../../assets/images/2.avif";
import image2 from "../../assets/images/5.jpg";
import image3 from "../../assets/images/img7.jpg";
import image4 from "../../assets/images/img2.webp";

const HomePage = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [activeIndex, setActiveIndex] = useState(null);
  const [options, setOptions] = useState([]);
  const [data, setData] = useState([]);
  const navigate = useNavigate();
  const [inputValue, setInputValue] = useState("");

  useEffect(() => {
    axios
      .get(`${API.devURL}/api/getExcel`)
      .then((res) => {
        console.log("Response Data22", res.data);
        //setCountryArray(res.data)
        const a = res.data[0].data.map((x, i) => {
          return {
            label: x[0] + " - " + x[1],
            value: x[0],
          };
        });
        console.log("excel", a);
        //setPartNumberArray(a)
        setData(a);
      })
      .catch((err) => {
        console.log("Err", err);
      });
  }, []);

  // const data = [
  //   { label: 'x', value: 'x' },
  //   { label: 'y', value: 'y' },
  //   { label: 'z', value: 'z' }
  // ];

  const handleButtonClick = () => {
    if (inputValue) {
      navigate("/shop", {
        state: { label: inputValue, value: inputValue },
      });
      localStorage.setItem("pot", inputValue);
    }
  };

  const handleSearch = (searchText) => {
    const filteredOptions = data.filter((item) =>
      item.label.toLowerCase().includes(searchText.toLowerCase())
    );
    setOptions(filteredOptions);
  };

  const handleSelect = (value) => {
    const selectedOption = data.find((item) => item.value === value);
    navigate("/shop", {
      state: { label: selectedOption.label, value: selectedOption.value },
    });
    localStorage.setItem("pot", selectedOption.value);
  };
  const toggleAccordion = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <div className="home-page">
      <Carousel arrows infinite={false} autoplay>
        <div>
          <h3
            style={{
              height: "100vh",
              color: "#fff",
              lineHeight: "160px",
              textAlign: "center",
              background: "#364d79",
              backgroundImage: `url(${image1})`,
              backgroundSize: "cover",
            }}
          ></h3>
        </div>
        <div>
          <h3
            style={{
              height: "100vh",
              color: "#fff",
              lineHeight: "160px",
              textAlign: "center",
              background: "#364d79",
              backgroundImage: `url(${image2})`,
              backgroundSize: "cover",
            }}
          ></h3>
        </div>
        <div>
          <h3
            style={{
              height: "100vh",
              color: "#fff",
              lineHeight: "160px",
              textAlign: "center",
              background: "#364d79",
              backgroundImage: `url(${image3})`,
              backgroundSize: "cover",
            }}
          ></h3>
        </div>
        <div>
          <h3
            style={{
              height: "100vh",
              color: "#fff",
              lineHeight: "160px",
              textAlign: "center",
              background: "#364d79",
              backgroundImage: `url(${image4})`,
              backgroundSize: "cover",
            }}
          ></h3>
        </div>
      </Carousel>
      <div
        style={{
          width: "100vw",
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <center>
          <h2
            style={{
              position: "absolute",
              zIndex: "10",
              top: "35%",
              right: "0%",
              color: "white",
              fontSize: "36px",
              textAlign: "center",
              width: "100%",
            }}
          >
            Explore Our Parts Collection
            <br></br>
             <div style={{
              display:'flex',
              justifyContent:'space-between',
              alignItems:'center',
              width:'75%'
             }}>
             <AutoComplete
            options={options}
            style={{
              position: "absolute",
              zIndex: "10",
              // top: "40%",
              // right: "30%",
              width: "40%",
               marginTop:windowWidth < 770 ? '10em' : "0em",
              marginLeft:"30%"
            }}
            onSearch={handleSearch}
            onSelect={handleSelect}
            placeholder="Search Parts Here..."
          >
            <Input
              value={inputValue}
              onChange={(e) => setInputValue(e.target.value)}
            />
          </AutoComplete>
          <br></br>
          <br></br>
          <Button
            type="primary"
            onClick={handleButtonClick}
            style={{
              position: "relative",
              zIndex: "10",
              //top: "44.45%",
              // marginLeft:'48%',
              // marginTop:windowWidth <= 770 ? '10%' : '10%'
              // marginTop:windowWidth < 770 ? '13em' : "0em",
              //right:windowWidth < 770 ? '28%' : "24%"
            }}
          >
            Search
          </Button>
             </div>
          </h2>
        </center>
      </div>

      <div className="what-we-do">
        <h2>What We Do</h2>
        <div className="card-container">
          <Link
            to="/what-we-do?topic=General-Aircraft-Support"
            className="card"
          >
            <img src={Productimg} alt="Product Support" />
            <div className="card-title">General Aircraft Support</div>
          </Link>

          <Link
            to="/what-we-do?topic=Commercial-Aircraft-Support"
            className="card"
          >
            <img src={Aircraftnimg} alt="Vision & Mission" />
            <div className="card-title">Commercial Aircraft Support</div>
          </Link>

          <Link
            to="/what-we-do?topic=Repair-and-Maintenance-Services"
            className="card"
          >
            <img src={Repairsimg} alt="Services" />
            <div className="card-title">Repairs & Maintenance Services</div>
          </Link>

          <Link
            to="/what-we-do?topic=Aviation-Logistics-Solutions"
            className="card"
          >
            <img src={Solutionimg} alt="Solutions" />
            <div className="card-title">Aviation Logistics Solutions </div>
          </Link>
        </div>
      </div>

      <div className="about-us-section">
        <div className="about-content">
          <h2>About Us</h2>
          <p>
            Arjuna Aerospace is swiftly emerging as a premier distributor of
            aircraft spare parts, lubricants, ground support equipment, and more
            across Asia, Africa, and the Middle East. Our growth is attributed
            to the collective expertise of our team, which delivers innovative
            and value-driven solutions. Over time, Arjuna Aerospace has
            cultivated strategic partnerships with leading global companies and
            professionals, enabling us to offer round-the-clock (24/7) services
            tailored to your aviation needs from our offices in UAE (Sharjah).
          </p>
          <Link to="/about-us#our-company" className="know-more-button">
            Know More
          </Link>
        </div>
        <div className="about-sidebar">
          <div className="accordion">
            <div className="accordion-item">
              <h3
                className="accordion-title"
                onClick={() => toggleAccordion(0)}
              >
                Our Company
              </h3>
              <div
                className={`accordion-content ${
                  activeIndex === 0 ? "active" : ""
                }`}
              >
                <p>
                  Arjuna Aerospace is swiftly emerging as a premier distributor
                  of aircraft spare parts, lubricants, ground support equipment,
                  and more across Asia, Africa, and the Middle East.
                  <a href="/about-us#our-company" className="read-more-link">
                    Read More...
                  </a>
                </p>
              </div>
              <h3
                className="accordion-title"
                onClick={() => toggleAccordion(1)}
              >
                Vision & Mission
              </h3>
              <div
                className={`accordion-content ${
                  activeIndex === 1 ? "active" : ""
                }`}
              >
                <p>
                  {" "}
                  Enhancing Customer delight by providing Realistic and
                  Efficient Aviation Solutions.
                  <a href="/about-us#vision-mission" className="read-more-link">
                    Read More...
                  </a>
                </p>
              </div>
              <h3
                className="accordion-title"
                onClick={() => toggleAccordion(2)}
              >
                Management
              </h3>
              <div
                className={`accordion-content ${
                  activeIndex === 2 ? "active" : ""
                }`}
              >
                <p>
                  "Surpassing customer expectations through transparency,
                  accuracy, efficiency, and timeliness."
                  <a href="/about-us#management" className="read-more-link">
                    Read More...
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomePage;

