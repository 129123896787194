import React from 'react';

import Image from "../assets/images/log-no-bg.png"
const Logo = () => {
    return (
        <div className="logo" style={{ marginLeft: "10px" }}>
            <img style={{ width: "85px", height: "80px" }} src={Image} alt="Aerospace Logo" />
        </div>
    );
};

export default Logo;
