import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "./login.css";
import { LockOutlined, UserOutlined } from "@ant-design/icons";
import {
  Button,
  Checkbox,
  Form,
  Input,
  Flex,
  Modal,
  Select,
  message,
} from "antd";
import axios from "axios";
import API from "../../baseURL.config.json";
import { jwtDecode } from "jwt-decode";

function Login() {
  const [form, setForm] = useState({
    email: "",
    password: "",
  });
  const [email, setEmail] = useState("");
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();

  const handleOk = () => {
    axios
      .post(`${API.devURL}/forgot-password`, {
        email: email,
      })
      .then((res) => {
        console.log("res", res.data);
        message.success("Please Check Your Mail Box");
        setOpen(false);
      }).catch((err) => {
        console.log("Error", err);
        message.error(err)
      })
  };
  const handleCancel = () => {
    setOpen(false);
  };
  const onFinish = (values) => {
    // console.log("Received values of form: ", values);
    axios
      .post(`${API.devURL}/login`, {
        username: values.username,
        password: values.password,
      })
      .then((res) => {
        console.log("Response", res.data);
        localStorage.setItem("token", res.data.token);
        const decoded = jwtDecode(res.data.token);
        localStorage.setItem("role", decoded.role);
        console.log("fff", decoded);
        message.success(`Logged In Succesfully as ${decoded.role}`).then(() => {
          navigate("/adminTable");
        });
      })
      .catch((err) => {
        console.log("Error", err);
      });
  };

  return (
    <div className="app-container">

      <Form
        className="login-form-ad"
        name="login"
        initialValues={{
          remember: true,
        }}

        onFinish={onFinish}
      >
        <Form.Item>
          <p
            style={{
              fontSize: "2rem",
              fontWeight: "bold",
              textAlign: "center",
            }}
          >
            Login Here
          </p>
        </Form.Item>
        <Form.Item
          name="username"
          rules={[
            {
              required: true,
              message: "Please input your Username!",
            },
          ]}
        >
          <Input prefix={<UserOutlined />} placeholder="Username" />
        </Form.Item>
        <Form.Item
          name="password"
          rules={[
            {
              required: true,
              message: "Please input your Password!",
            },
          ]}
        >
          <Input.Password
            prefix={<LockOutlined />}
            type="password"
            placeholder="Password"
          />
        </Form.Item>
        <Form.Item>
          <Flex justify="space-between" align="center">
            <Button
              block
              type="primary"
              htmlType="submit"
              style={{
                width: "20%",
              }}
            >
              Log in
            </Button>
            <Button
              // block
              style={{
                width: "20%",
              }}
              type="link"
              onClick={() => setOpen(true)}
            >
              Forgot Password ?
            </Button>
          </Flex>
        </Form.Item>
      </Form>
      {/* modal */}
      <Modal
        open={open}
        title="Forgot Passwors"
        onOk={handleOk}
        onCancel={handleCancel}
        footer={[
          <Button key="back" onClick={handleCancel}>
            Close
          </Button>,
          <Button key="submit" type="primary" onClick={handleOk}>
            Submit
          </Button>,
        ]}
      >
        <Form layout="vertical">
          <Form.Item label="Enter Your Email">
            <Input value={email} onChange={(e) => setEmail(e.target.value)} />
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
}

export default Login;
