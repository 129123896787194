// import React, { useEffect, useState } from "react";
// import "./careers.css";
// import RightSideImage from "../../assets/images/new-about.jpeg"; // Add your image path
// import { Link } from "react-router-dom";
// import { Row, Col, Flex, Button, Modal, Form, Input, message } from "antd";
// import API from "../../baseURL.config.json";
// import axios from "axios";
// const Careers = () => {
//   const [careerState, setCareerState] = useState([]);
//   const [openModal, setOpenModal] = useState(false);
//   const [careerData, setCareerData] = useState({
//     job_description: "",
//     job_title: "",
//     full_name: "",
//     mobile_no: "",
//     email: "",
//     experience: "",
//   });

//   const updateData = (updates) => {
//     setCareerData((prevState) => ({
//       ...prevState,
//       ...updates,
//     }));
//   };

//   useEffect(() => {
//     axios
//       .get(`${API.devURL}/getJobs`)
//       .then((res) => {
//         console.log("res career", res.data);
//         setCareerState(res.data);
//       })
//       .catch((err) => {
//         console.log("Err", err);
//       });
//   }, []);

//   const applyNow = (x) => {
//     console.log("x", x);
//     setOpenModal(true);
//     updateData({
//       job_title: x.job_title,
//       job_description: x.job_description,
//     });
//   };

//   const handleOk = () => {
//     axios.post(`${API.devURL}/api/sendToCareers`,{
//       job_description: careerData.job_description,
//       job_title: careerData.job_title,
//       full_name: careerData.full_name,
//       mobile_no: careerData.mobile_no,
//       email: careerData.email,
//       experience: careerData.experience
//     },{
//       headers: {
//         Authorization: `Bearer ${localStorage.getItem("token")}`,
//       },
//     }).then((res) => {
//       console.log("Response",res.data)
//       message.success("Your Information is send to HR team......and our Will Revert")
//     }).catch((err) => {
//       console.log("Error",err)
//     })
//   };
//   const handleCancel = () => {
//     setOpenModal(false)
//   };
//   return (
//     <>
//       <div className="careers-container">
//         <div className="careers-content">
//           <h1>Join Our Team</h1>
//           <p>
//             Arjuna Aerospace is one of the fastest-growing aviation companies,
//             serving the Middle East, Africa, Southeast Asia, and the CIS region.
//           </p>
//           <p>
//             {" "}
//             We are constantly seeking talented and forward-thinking individuals
//             who are eager to apply their skills and experience to drive both
//             their personal growth and our company’s success. If you are
//             passionate about innovation and committed to excellence, we invite
//             you to explore career opportunities with Arjuna Aerospace.
//           </p>
//           <button className="careers-button">
//             <Link to="/contact" className="careers-link">
//               Explore Careers
//             </Link>
//           </button>
//         </div>
//         <div className="careers-image-container">
//           <img src={RightSideImage} alt="Careers" className="careers-image" />
//         </div>
//       </div>
//       <Row>
//         <Col
//           xs={24}
//           lg={24}
//           sm={24}
//           md={24}
//           style={{
//             padding: "1em",
//           }}
//         >
//           {careerState &&
//             careerState.map((x, i) => {
//               return (
//                 <Flex
//                   justify="space-between"
//                   style={{
//                     marginBottom: "1em",
//                   }}
//                 >
//                   <div>
//                     <p
//                       style={{
//                         fontWeight: "bold",
//                         fontSize: "2em",
//                       }}
//                     >
//                       {x.job_title}
//                     </p>
//                     <p>
//                       <strong>Vacancy: </strong> {x.job_size}
//                     </p>
//                     <p>
//                       <strong>Salary: </strong> {x.job_salary_range}
//                     </p>
//                     <p>
//                       <strong>Location: </strong> {x.job_location}
//                     </p>
//                   </div>
//                   <div>
//                     <p>{x.job_description}</p>
//                   </div>
//                   <div>
//                     <Button type="primary" onClick={() => applyNow(x)}>
//                       Apply Now
//                     </Button>
//                   </div>
//                 </Flex>
//               );
//             })}
//         </Col>
//       </Row>
//       {/* careers modal */}
//       <Modal
//         open={openModal}
//         onClose={handleCancel}
//         footer={[
//           <Button key="back" onClick={handleCancel}>
//             Cancel
//           </Button>,
//           <Button key="submit" type="primary" onClick={handleOk}>
//             Apply
//           </Button>,
//         ]}
//       >
//         <Form>
//           <Form.Item>
//             <p
//               style={{
//                 fontSize: "2em",
//               }}
//             >
//               Job Title
//             </p>
//           </Form.Item>
//           <Form.Item label="Fullname">
//             <Input
//               value={careerData.full_name}
//               onChange={(e) =>
//                 updateData({
//                   full_name: e.target.value,
//                 })
//               }
//             />
//           </Form.Item>
//           <Form.Item label="Mobile No">
//             <Input
//               value={careerData.mobile_no}
//               onChange={(e) =>
//                 updateData({
//                   mobile_no: e.target.value,
//                 })
//               }
//             />
//           </Form.Item>
//           <Form.Item label="Email">
//             <Input
//               value={careerData.email}
//               onChange={(e) =>
//                 updateData({
//                   email: e.target.value,
//                 })
//               }
//             />
//           </Form.Item>
//           <Form.Item label="Job Title">
//             <Input
//               value={careerData.job_title}
//               onChange={(e) =>
//                 updateData({
//                   job_title: e.target.value,
//                 })
//               }
//             />
//           </Form.Item>
//           <Form.Item label="Job Description">
//             <Input
//               value={careerData.job_description}
//               onChange={(e) =>
//                 updateData({
//                   job_description: e.target.value,
//                 })
//               }
//             />
//           </Form.Item>
//           <Form.Item label="Experience (In Years)">
//             <Input
//               value={careerData.experience}
//               onChange={(e) =>
//                 updateData({
//                   experience: e.target.value,
//                 })
//               }
//             />
//           </Form.Item>
//         </Form>
//       </Modal>
//     </>
//   );
// };

// export default Careers;
import React, { useEffect, useState } from "react";
import "./careers.css";
import RightSideImage from "../../assets/images/new-about.jpeg";
import { Link } from "react-router-dom";
import { Row, Col, Button, Modal, Form, Input, Upload, message, Flex } from "antd";
import API from "../../baseURL.config.json";
import axios from "axios";
import { UploadOutlined } from '@ant-design/icons';

const Careers = () => {
  const [careerState, setCareerState] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [careerData, setCareerData] = useState({
    job_description: "",
    job_title: "",
    full_name: "",
    mobile_no: "",
    email: "",
    experience: "",
  });
  const [resume, setResume] = useState(null);
  const [mail,setMail] = useState("");
  const updateData = (updates) => {
    setCareerData((prevState) => ({
      ...prevState,
      ...updates,
    }));
  };

  useEffect(() => {
    axios
      .get(`${API.devURL}/getJobs`)
      .then((res) => {
        setCareerState(res.data);
      })
      .catch((err) => {
        console.log("Err", err);
      });
    
        axios.get(`${API.devURL}/getCareersMail`)
        .then((res) =>{
          setMail(res.data.careers_email)
        }).catch((err) => {
          message.error(err)
        })
  
  }, []);

  const applyNow = (x) => {
    setOpenModal(true);
    updateData({
      job_title: x.job_title,
      job_description: x.job_description,
    });
  };

  const handleOk = () => {
    if (!resume) {
      message.error("Please upload your resume before submitting.");
      return;
    }

    // Creating FormData object to send resume file with other data
    const formData = new FormData();
    formData.append("resume", resume); // Resume file
    formData.append("job_description", careerData.job_description);
    formData.append("job_title", careerData.job_title);
    formData.append("full_name", careerData.full_name);
    formData.append("mobile_no", careerData.mobile_no);
    formData.append("email", careerData.email);
    formData.append("experience", careerData.experience);
    formData.append("add_mail", mail);

    // Send data and file to backend
    axios
      .post(`${API.devURL}/api/sendToCareers`, formData, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          'Content-Type': 'multipart/form-data'
        },
      })
      .then((res) => {
        message.success("Your application has been sent to HR.");
        setOpenModal(false);
      })
      .catch((err) => {
        message.error("Failed to send application. Please try again.");
      });
  };

  const handleCancel = () => {
    setOpenModal(false);
  };

  const handleResumeUpload = ({ file }) => {
    setResume(file); // Store the uploaded file in the state
  };

  return (
    <>
       <div className="careers-container">
         <div className="careers-content">
           <h1>Join Our Team</h1>
           <p>
             Arjuna Aerospace is one of the fastest-growing aviation companies,
             serving the Middle East, Africa, Southeast Asia, and the CIS region.
           </p>
           <p>
             {" "}
             We are constantly seeking talented and forward-thinking individuals
             who are eager to apply their skills and experience to drive both
             their personal growth and our company’s success. If you are
             passionate about innovation and committed to excellence, we invite
             you to explore career opportunities with Arjuna Aerospace.
           </p>
           <button className="careers-button">
             <Link to="/contact" className="careers-link">
               Explore Careers
             </Link>
           </button>
         </div>
         <div className="careers-image-container">
           <img src={RightSideImage} alt="Careers" className="careers-image" />
         </div>
       </div>
      <Row>
        <Col xs={24} lg={24} sm={24} md={24} style={{ padding: "1em" }}>
          {careerState &&
            careerState.map((x, i) => {
              return (
                <Flex key={i} style={{ marginBottom: "1em" }}  justify="space-between">
                  <div>
                    <p style={{ fontWeight: "bold", fontSize: "2em" }}>{x.job_title}</p>
                    <p><strong>Vacancy:</strong> {x.job_size}</p>
                    <p><strong>Salary:</strong> {x.job_salary_range}</p>
                    <p><strong>Location:</strong> {x.job_location}</p>
                  </div>
                  <div>
                    <p>{x.job_description}</p>
                  </div>
                  <div>
                    <Button type="primary" onClick={() => applyNow(x)}>Apply Now</Button>
                  </div>
                </Flex>
              );
            })}
        </Col>
      </Row>
      {/* Careers modal */}
      <Modal
        open={openModal}
        onCancel={handleCancel}
        footer={[
          <Button key="back" onClick={handleCancel}>Cancel</Button>,
          <Button key="submit" type="primary" onClick={handleOk}>Apply</Button>,
        ]}
      >
        <Form>
        <Form.Item >
          <h1>Please Fill The Application</h1>
          </Form.Item>
          <Form.Item label="Full Name">
            <Input
              value={careerData.full_name}
              onChange={(e) => updateData({ full_name: e.target.value })}
            />
          </Form.Item>
          <Form.Item label="Mobile No">
            <Input
              value={careerData.mobile_no}
              onChange={(e) => updateData({ mobile_no: e.target.value })}
            />
          </Form.Item>
          <Form.Item label="Email">
            <Input
              value={careerData.email}
              onChange={(e) => updateData({ email: e.target.value })}
            />
          </Form.Item>
          <Form.Item label="Experience (In Years)">
            <Input
              value={careerData.experience}
              onChange={(e) => updateData({ experience: e.target.value })}
            />
          </Form.Item>
          <Form.Item label="Upload Resume">
            <Upload
              beforeUpload={() => false} // Prevent immediate upload
              onChange={handleResumeUpload}
              accept=".doc,.docx,.pdf"
            >
              <Button icon={<UploadOutlined />}>Click to Upload Resume</Button>
            </Upload>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default Careers;
